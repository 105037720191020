.team-card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  flex-wrap: wrap;
  text-align: left;
  padding: 0 $spacing-large;
  
  @include media($lg-up) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  &:focus {
    box-shadow: none;
  }
}

.team-card__image-wrapper {
  position: relative;
  transition: transform $animation-base-duration;
  
  &::before {
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-gradient-primary;
    position: absolute;
    transition: transform $animation-base-duration;
    z-index: 0;
  }

  .team-card:hover > &, .team-card:focus > &  {
    transform: translate(15px, 15px);
    
    &::before {
      transform: translate(-30px, -30px);
    }
  }
}

.team-card__image {
  position: relative;
  z-index: 1;
}

.team-card__text-wrapper {
  padding: $spacing-large;
}

.team-card__quote {
  font-size: 1.25rem;
  font-weight: $font-weight-light;
  color: $color-gray-dark;
  margin: $spacing-large 0;
  position: relative;

  &::before, &::after {
    font-size: 3.125rem;
    font-weight: $font-weight-bold;
    position: absolute;
    line-height: .5;
  }

  &::before {
    content: '“';
    top: 0;
    left: 0;
    transform: translateY(-75%);
  }

  &::after {
    content: '”';
    bottom: 0;
    right: 0;
    transform: translateY(75%);
  }
}

.team-card__date {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-size: .625rem;
  color: $color-primary;
  margin: 0 0 $spacing-small;
}

.team-card__function {
  font-weight: $font-weight-light;
  font-size: .75rem;
  color: $color-gray-dark;
  margin: 0 0 $spacing-small;
}

.team-card__name {
  text-transform: uppercase;
  font-weight: $font-weight-black;
  font-size: .875rem;
  color: $color-gray-dark;
  margin: 0;
}