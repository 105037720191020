.header {
  padding-top: $spacing-base;
  padding-bottom: $spacing-base+$spacing-small;
  position: relative;
  z-index: 2;
  display: flex;
  justify-items: space-between;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  margin-left: 0;
  margin-right: 0;
  
  @include media($md-up) {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.header__logo-wrapper {
  width: $spacing-larger;
  max-width: 71px;
  display: block;
  position: relative;
  z-index: 10;
  
  @include media($md-up) {
    width: 10%;
  }
}

.header__logo {
  width: 100%;
  height: auto;
}

.header__nav {
  @include media($md-up) {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}