/**
 * Layout wrappers
 */

.wrapper-inner {
  @include row;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter / 2;
  padding-right: $gutter / 2;
  width: 100%;
  max-width: $max-width;

  @include media($sm-down) {
    &::after {
      display: none;
    }
  }
}

.wrapper-inner--vspacing {
  @include v-spacing($spacing-base);

  @include media($md-up) {
    @include v-spacing($spacing-base * 2);
  }
}

.btn-wrapper {
  width: 100%;
  text-align: center;
}

.wrapper-page {
  padding: 0 10px;
  position: relative;
  z-index: 1;
  
  @include media($md-up) {
    padding: 0 70px;
  }
}