.color-primary {
  color: $color-primary;
}

.fill-primary {
  fill: $color-primary;
}

.bg-primary {
  background-color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

.fill-secondary {
  fill: $color-secondary;
}

.bg-secondary {
  background-color: $color-secondary;
}
