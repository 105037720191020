body,
html {
  margin: 0;
  background: $color-base-background;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;

  @include media($lg-up) {
    overflow: scroll;
  }

  @include media($sm-down) {
    &.is-fixed {
      position: fixed;
    }
  }
}

body {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    z-index: 0;
    width: 100%;
    transform: translateY(-50%) skewY($angle-base);
    height: 50vh;
    background: $color-gradient-primary;
    border-bottom: 12.5vh solid transparentize($color-gray-dark, .5);
  }
}