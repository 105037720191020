.layout2__section {
  padding: $spacing-larger $spacing-large $spacing-larger;
  position: relative;
  text-align: center;
  
  @include media($md-up) {
    padding: $spacing-larger $spacing-larger $spacing-larger;
  }
}

.layout2__header {
  text-align: center;
  position: relative;
  margin-bottom: $spacing-larger*1.5;
  margin-top: $spacing-base;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) skewY($angle-medium);
    background: transparentize($color-secondary, .9);
    max-width: 318px;
    width: 100%;
    height: 100%;
    max-height: 131px;
  }
}

.layout2__title {
  text-transform: uppercase;
  color: $color-primary;
  font-size: 1.25rem;
  font-weight: $font-weight-black;
  margin: 0 auto;
}

.layout2__heading {
  font-weight: $font-weight-light;
}

.layout2__subtitle {
  color: $color-gray-light;
  font-size: 1.125rem;
  margin: $spacing-base auto 0;
}

.layout2__intro-text {
  font-weight: $font-weight-bold;
  color: $color-primary;
  font-style: italic;
  font-size: 1.125rem;
  margin-bottom: $spacing-larger;
  text-align: left;
}

.layout2__paragraph-wrapper {
  margin: 0 0 $spacing-base;
  text-align: left;
}

.layout2__paragraph-title {
  font-weight: $font-weight-bold;
  font-style: italic;
  font-size: 1.125rem;
  color: $color-dark;
  margin: 0 0 $spacing-smaller;
}

.layout2__paragraph {
  font-weight: $font-weight-normal;
  color: $color-gray-light;
  font-size: 0.875rem;
  margin: 0;
}

.layout2__section-title {
  font-weight: $font-weight-bold;
  font-style: italic;
  color: $color-primary;
  font-size: 1.125rem;
  position: relative;
  display: inline-block;
  margin: 0 auto $spacing-large;

  &::before {
    content: '';
    position: absolute;
    left: $spacing-smaller;
    right: $spacing-smaller;
    top: 0;
    bottom: 0;
    background: transparentize($color-secondary, .9);
    transform: skewY($angle-medium);
  }
}

.layout2__section-intro {
  color: $color-gray-light;
  font-weight: $font-weight-light;
  font-size: 1.25rem;
  margin: 0 auto $spacing-large;
}