// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,900');
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');

// Typography
$font-family-regular: 'Lato', sans-serif;
$font-family-heading: 'Lato', sans-serif;

$font-weight-hairline: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

$font-weight-regular: $font-weight-normal;
$font-weight-heading: $font-weight-hairline;

// Font Sizes
$font-size-base: .75rem;
$font-size-larger: 1.5rem;
$font-size-large: 1.25rem;
$font-size-small: .4rem;
$font-size-smaller: .2rem;
$font-size-button: 1.0625rem;

// Line height
$line-height-base: 2;
// $line-height-base: 1.167;
$line-height-heading: 1;

// Heading scale
$heading-scale: 1.21;

$font-responsive-base: .5vw;