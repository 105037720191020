// a11y stuff
@media screen and (prefers-reduced-motion) {

  *,
  *::after,
  *::before {
    transition: none !important;
    animation: none !important;
  }
}

.sr-only {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
