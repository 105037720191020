.top-nav__list {
  margin: 0;
  list-style-type: none;
  padding: 0;
}

.top-nav__item {
  margin: $spacing-small*1.5 0;

  @include media($md-up) {
    display: inline-block;
    margin: 0;
  }
}

.top-nav__link {
  color: $color-light;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: $font-weight-bold;

  &:hover, &:focus {
    text-decoration: underline;
  }

  @include media($md-up) {
    margin: 0 $spacing-small*1.5;
  }
}