// #{$all-buttons} {
//   @include btn;
// }

.btn, button, input[type='button'] {
  min-height: 41px - $border-base-width*2;
  text-transform: uppercase;
  color: $color-light;
  font-size: 0.625rem;
  padding: $spacing-small $spacing-base+$spacing-small;
  border: $border-base;
  font-weight: $font-weight-bold;
  border-bottom: none;
  display: inline-block;
  position: relative;
  transition: all $animation-base-duration;
  text-decoration: none;
  min-width: 150px;
  z-index: 1;

  &::before {
    content: '';
    transform: skewY($angle-small);
    height: 100%;
    border: $border-base;
    border-top: none;
    position: absolute;
    top: 31%;
    right: -$border-base-width;
    bottom: 0;
    left: -$border-base-width;
    z-index: -1;
  }

  &:hover, &:focus {
    transform: translate($spacing-smaller, $spacing-smaller);
  }
}

.btn--inverted {
  color: $color-primary;
  border-color: $color-primary;

  &::before {
    border-color: $color-primary;
  }
}

.btn--filled {
  color: $color-light;
  background: $color-primary;
  min-height: 2.5625rem;
  border: none;
  
  &::before {
    background: $color-primary;
    border: none;
    left: 0;
    right: 0;
    top: 23%;
  }
}