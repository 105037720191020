/**
 * List mixins to add base styling
 */

@mixin list-clean {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@mixin list-padding {
  padding-left: 20px;
}

@mixin list-decimal {
  @include list-padding;

  list-style-type: decimal;
}

@mixin list-bullets {
  @include list-padding;

  list-style-type: disc;
}
