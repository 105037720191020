.slider {
  
}


.slider__dots-container {
  margin: 0 auto;
  padding: $spacing-large;
  list-style-type: none;
  display: flex;
  justify-content: center;

  & > li {
    margin: 0 $spacing-smaller;
    width: .5rem;
    height: .5rem;

    button {
      min-width: 0;
      min-height: 0;
      font-size: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      border: none;
      background: $color-gray-ultra-light;

      &:hover, &:focus {
        transform: translate(10%, 10%);
        outline: none;
      }

      &::before {
        display: none;
      }
    }

    &.slick-active {
      button {
        background: $color-gradient-primary;
      }
    }
  }
}