.hamburger {
  background: $color-primary;
  position: absolute;
  top: 0;
  bottom: 0;
  left: $spacing-larger;
  right: 0;
  height: 100vh;
  z-index: 3;
  padding: $spacing-base*1.5;
  transform: translateX(100%);
  transition: all $animation-base-duration;
  opacity: 0;
  max-width: calc(100vw - 40px);

  .hamburger__checkbox:checked ~ & {
    transform: translateX(0);
    opacity: 1;
  }
  
  @include media($md-up) {
    max-width: none;
    position: initial;
    background: none;
    transform: none;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    opacity: 1;
    height: auto;

    .hamburger__checkbox:checked ~ & {
      transform: none;
    }
  }
}

.hamburger__icon {
  margin: 0;
  width: auto;
  padding: $spacing-small;
  position: relative;
  top: -$spacing-smaller;
  z-index: 10;
  user-select: none;

  @include media($md-up) {
    display: none;
  }
}

.hamburger__stick {
  width: 25px;
  height: 3px;
  background: $color-light;
  transition: all $animation-base-duration;

  &--middle {
    opacity: 1;
  }

  &--top {
    transform: translateY(-150%);
  }

  &--bottom {
    transform: translateY(150%);
  }

  .hamburger__checkbox:checked ~ .hamburger__icon > & {
    &--middle {
      opacity: 0;
    }

    &--top {
      transform: translateY(3px) rotate(45deg);
    }

    &--bottom {
      transform: translateY(-3px) rotate(-45deg);
    }
  }
}