img,
picture {
  display: block;
  margin: 0;
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}
