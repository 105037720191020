/**
* Button mixins
*/
@mixin btn {
  @include btn-color();

  min-width: 150px;
  display: inline-block;
  appearance: none;
  border: 0;
  border-radius: $border-radius-button;
  color: #fff;
  cursor: pointer;
  font-family: $font-family-heading;
  font-size: calc(#{$font-size-base} + #{$font-responsive-base});
  font-weight: $font-weight-heading;
  -webkit-font-smoothing: antialiased;
  line-height: 1.25;
  padding: $spacing-small $spacing-base;
  text-decoration: none;
  text-align: center;
  transition: background-color $animation-base-duration $animation-base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {

    &:first-child {
      margin-right: $spacing-small;
    }

    &:last-child {
      margin-left: $spacing-small;
    }

    &:first-child:last-child {
      margin: 0;
    }
  }
}

// Button sizing
@mixin btn-sm {
  padding: $spacing-small / 2 $spacing-base;
  font-size: calc(#{$font-size-smaller} + #{$font-responsive-base});
}

@mixin btn-md {
  font-size: calc(#{$font-size-small} + #{$font-responsive-base});
}

// Button skins
@mixin btn-primary {
  @include btn-color(background-color, $color-primary);
}

@mixin btn-secondary {
  @include btn-color(background-color, $color-secondary);
}

@mixin btn-tertiary {
  @include btn-color(background-color, $color-tertiary);
  @include btn-color(color, $color-base-font);
}

// Ghost buttons
@mixin btn-ghost {
  border: 1px solid currentColor;
  box-shadow: none;
  background: #fff;

  &:focus,
  &:hover {
    background: #fff;
  }
}

@mixin btn-ghost-primary {
  @include btn-color(color, $color-primary);
  @include btn-hover-color(background-color, $color-primary);
  @include btn-hover-color(color, #fff);
}

@mixin btn-ghost-secondary {
  @include btn-color(color, $color-secondary);
  @include btn-hover-color(background-color, $color-secondary);
  @include btn-hover-color(color, #fff);
}

@mixin btn-ghost-tertiary {
  @include btn-color(border-color, $color-tertiary);
  @include btn-color(color,  $color-secondary);
  @include btn-hover-color(border-color, $color-secondary);
  @include btn-hover-color(color, $color-secondary);
}

@mixin btn-clean {
  margin: 0;
  border-radius: 0;
  padding: 0;
  font-family: $font-family-regular;
  font-weight: $font-weight-regular;
  color: $color-base-font;
  box-shadow: none;
  background-color: transparent;

  &:focus,
  &:hover {
    background-color: transparent;
  }
}
