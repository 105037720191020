.row-list {
  @include expand;
  transform: skewY($angle-base);
  background: $color-light;
  padding-top: $spacing-larger;
  padding-bottom: $spacing-larger;
  margin-top: -$spacing-larger*2;
  
  @include media($lg-up) {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  
  &--links {
    margin-top: 350px;
    padding-top: 0;
    padding-bottom: $spacing-larger*2;

    @include media($lg-up) {
      margin-top: 150px;
    }
  }
}

.row-list__item {
  width: 100%;
  display: block;
  text-decoration: none;
  position: relative;
  margin-bottom: $spacing-base;
  transform: skewY(-$angle-base);
  
  @include media($lg-up) {
    width: 30%;
    margin-bottom: 0;
  }

  &--link {
    transition: transform $animation-base-duration;
    transform: skewY(-$angle-base) translateY(-275px);
    box-shadow: 0 0 30px transparentize($color-dark, .8);
  
    @include media($lg-up) {
      transform: skewY(-$angle-base) translateY(-125px);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      background: $color-gradient-primary;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: transform $animation-base-duration;
      z-index: -2;
    }
    
    &:hover, &:focus {
      transform: skewY(-$angle-base) translate(15px, -260px);
      
      @include media($lg-up) {
        transform: skewY(-$angle-base) translate(15px, -110px);
      }
  
      &:after {
        transform: translate(-30px, -30px);
      }
    }
  }
}

.row-list__image {
  height: 400px;
  width: 100%;
  position: relative;
  z-index: -1;
  object-fit: cover;
  object-position: center center;
  
  @include media($lg-up) {
    height: 175px;
  }
}

.row-list__description {
  position: relative;
  padding: $spacing-small $spacing-base $spacing-base;
  min-height: 250px;
  
  @include media($lg-up) {
    min-height: 0;
  }
  
  .row-list__item--link & {
    background: $color-light;

    &::before {
      content: '';
      background: $color-light;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      height: 250px;
      transform: skewY($angle-base) translateY(-50%);
      
      @include media($lg-up) {
        height: 100px;
      }
    }
  }
}

.row-list__header {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: $color-primary;
  margin-bottom: $spacing-base;
  font-size: 0.625rem;
}

.row-list__title {
  font-size: 0.9625rem;
  font-weight: $font-weight-hairline;
}

.row-list__text {
  font-size: 0.75rem;
  line-height: 2;
  color: $color-gray-light;
  width: 100%;
}