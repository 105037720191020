@mixin expand {
  width: calc(100% + #{$spacing-large} * 2 );
  margin-left: -$spacing-large;
  padding-left: $spacing-large;
  padding-right: $spacing-large;
  
  @include media($md-up) {
    width: calc(100% + #{$spacing-larger} * 2 );
    margin-left: -$spacing-larger;
    padding-left: $spacing-larger;
    padding-right: $spacing-larger;
  }
}