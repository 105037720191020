.layout1__section {
  padding: $spacing-larger $spacing-large $spacing-larger;
  position: relative;
  
  @include media($md-up) {
    padding: $spacing-larger $spacing-larger $spacing-larger;
  }

  &--news {
    background: $color-gradient-primary;
    margin-bottom: -$spacing-larger*5;
    
    @include media($lg-up) {
      margin-bottom: -$spacing-larger*3;
    }
  }

  &--about {
    background: $color-gradient-primary;
    z-index: 1;

    .layout1__section-part {
      @include expand;
      background: $color-light;
      margin-top: -$spacing-larger;
      padding-top: $spacing-larger;
      padding-bottom: $spacing-larger*2;
    }

    .layout1__title {
      color: $color-dark;
    }

    .layout1__subtitle {
      color: $color-primary;
      
      &::before {
        background: $color-primary;
      }
    }

    .layout1__description {
      color: $color-gray-medium;
    }
  }

  &--footer {
    background: $color-gradient-primary;
    text-align: center;
    z-index: 0;
  }
}

.layout1__heading {
  position: relative;
  z-index: 1;
}

.layout1__header-text {
  width: 66.66666%;
  color: $color-gray-light;
}

.layout1__header-image {
  width: 33.33333%;
  position: absolute;
  right: 0;
  top: 25%;
  transform: translateY(-25%);
  z-index: 0;
  
  @include media($md-up) {
    top: 10%;
    transform: translateY(-5%);
  }
  
  @include media($lg-up) {
    top: 25%;
    transform: translateY(-25%);
  }
}

.layout1__title {
  font-size: 1.875rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $color-light;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: 0 0;
  display: inline-block;
  opacity: .5;
  position: absolute;
  letter-spacing: .5rem;
}

.layout1__subtitle {
  color: $color-light;
  font-style: italic;
  font-weight: $font-weight-bold;
  position: relative;
  font-size: 1.25rem;
  display: table;
  margin-left: $spacing-larger;
  margin-bottom: $spacing-large;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    bottom: -50%;
    left: $spacing-small;
    right: $spacing-small;
    transform: skewY($angle-base);
    background: $color-light;
    opacity: .1;
  }
}

.layout1__description {
  margin-left: $spacing-larger;
  margin-bottom: $spacing-large;
  font-weight: $font-weight-hairline;
  color: $color-light;
  font-size: 1.125rem;
}

.layout1__button {
  margin-left: $spacing-larger;
  margin-bottom: $spacing-large;
}

.layout1__large-text {
  font-weight: $font-weight-hairline;
  color: $color-light;
  font-size: 2.25rem;
}

.layout1__form {
  margin-top: $spacing-larger;
}