/**
 * Form validation styling
 */

.parsley-error-line.filled {
  display: block;
  margin-bottom: $spacing-small;
  border-left: 2px solid $color-red;
  padding: 0 $spacing-small;
}

.parsley-error {
  box-shadow: inset 14px 0 0 -10px $color-red !important;
}
