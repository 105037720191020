.footer {
  background: $color-gray-dark;
  color: $color-light;
  padding: $spacing-large $spacing-base $spacing-larger;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  
  @include media($md-up) {
    padding: $spacing-large $spacing-large $spacing-larger;
  }
  
  @include media($lg-up) {
    flex-wrap: nowrap;
  }
}

.footer__block {
  width: 50%;
  margin-bottom: $spacing-large;
  
  @include media($md-up) {
    width: 33.3333%;
  }
  
  @include media($lg-up) {
    margin-bottom: 0;
    width: 20%;
  }
}

.footer__title {
  margin: 0 0 $spacing-small;
  text-transform: uppercase;
  font-size: .75rem;
  letter-spacing: 2%;
  font-weight: $font-weight-black;
}

.footer__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: $color-gray-ultra-light;
  font-weight: $font-weight-normal;
  font-size: .875rem;
  
  &--horizontal {
    display: flex;
    justify-content: flex-start;

    .footer__list-item {
      margin-right: $spacing-base*1.5;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.footer__link {
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: underline;
  }
  
  &--image {  
    &:hover, &:focus {
      text-decoration: none;
    }
  }
}

.footer__copyright {
  font-size: .5625rem;
  color: $color-gray-ultra-light;
}

.footer__logo-wrapper {
  text-decoration: none;
}