.layout3__section {
  padding: $spacing-larger $spacing-large $spacing-larger;
  position: relative;

  @include media($md-up) {
    padding: $spacing-larger $spacing-larger $spacing-larger;
  }
}

.layout3__header {
  position: relative;
  margin-bottom: $spacing-larger*1.5;
  margin-top: $spacing-base;
}

.layout3__title {
  text-transform: uppercase;
  color: $color-primary;
  font-size: 1.25rem;
  font-weight: $font-weight-black;
  margin: 0 auto $spacing-small*1.5;
}

.layout3__heading {
  font-weight: $font-weight-light;
  margin-bottom: $spacing-base;
}

.layout3__subtitle {
  color: $color-gray-light;
  font-size: 1.125rem;
  margin: $spacing-base auto 0;
}

.layout3__paragraph-wrapper {
  margin: 0 0 $spacing-large;
  text-align: left;
}

.layout3__link-title {
  font-weight: $font-weight-bold;
  font-size: 1.125rem;
  color: $color-dark;
  margin: 0 0 $spacing-smaller;
  text-transform: uppercase;
}

.layout3__link {
  font-weight: $font-weight-normal;
  color: $color-gray-light;
  font-size: 0.875rem;
  margin: 0;
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: underline;
  }
}