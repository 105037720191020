/**
 * Hide items on certain breakpoints
 */

@include media($sm-down) {
  .hide-sm-down {
    display: none !important;
  }
  .show-sm-down {
    display: block !important;
  }
}

@include media($md-down) {
  .hide-md-down {
    display: none !important;
  }
  .show-md-down {
    display: block !important;
  }
}

@include media($lg-down) {
  .hide-lg-down {
    display: none !important;
  }
  .show-lg-down {
    display: block !important;
  }
}

@include media($sm-up) {
  .hide-sm-up {
    display: none !important;
  }
  .show-sm-up {
    display: block !important;
  }
}

@include media($md-up) {
  .hide-md-up {
    display: none !important;
  }
  .show-md-up {
    display: block !important;
  }
}

@include media($lg-up) {
  .hide-lg-up {
    display: none !important;
  }
  .show-lg-up {
    display: block !important;
  }
}

@include media($xl-up) {
  .hide-xl-up {
    display: none !important;
  }
  .show-xl-up {
    display: block !important;
  }
}
